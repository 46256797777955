import {SbBlokData, StoryblokComponent, storyblokEditable, useStoryblokApi} from "@storyblok/react";
import {IFeatureItem} from "../../types/IFeatureItem";
import ButtonGroup from "../partials/ButtonGroup";
import {useEffect, useState} from "react";
import classNames from "classnames";
import {ArrowRightIcon} from "@heroicons/react/20/solid";
import React from "react";
import {richTextResolver} from "@storyblok/richtext";


interface IProps {
    blok: { title: string, subtitle: string, container_padding: string, items: Array<IFeatureItem>, buttons: Array<any> } & SbBlokData
}

export default function RevealFeatures(props: IProps) {
    const blok = props.blok;
    const buttons = <ButtonGroup buttons={props.blok.buttons ?? []}/>
    const { render } = richTextResolver()

    const blokId = blok._uid;

    const [currentIndex, setCurrentIndex] = useState(0);
    const [nestedBlocks, setNestedBlocks] = useState(<div></div>);

    useEffect(() => {

        setNestedBlocks(<>
            {blok.items[currentIndex]?.sub_blocks?.map((nestedBlok: any, x: number) => (
                <StoryblokComponent blok={nestedBlok} key={x}/>
            ))}
        </>);

        if (currentIndex !== 0) {
            let item = document.getElementById(blokId + 'container');

            if (window.innerWidth < 480) {
                item = document.getElementById(blokId + 'revealHeading' + currentIndex);
                // scroll to item smoothly
                item?.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'});
            }


        }
    }, [currentIndex])

    return (
        <div className={blok.container_padding || 'py-12'}  {...storyblokEditable(props.blok)}>
            <div className={'py-12'}>

                <div

                    className="mb-12 px-6 lg:px-8">
                    <div className="mx-auto max-w-4xl text-center"
                         data-aos={'fade-in'}
                    >
                        <h3 className={(props.blok.size || 'text-4xl sm:text-4xl') + " font-bold tracking-tight text-gray-900"}>
                            {blok.title}
                        </h3>
                        <p className="mt-6 text-lg leading-8 text-gray-600">
                            {blok.subtitle}
                        </p>

                        {buttons}
                    </div>
                </div>

                <div className={'px-6'} id={blokId + 'container'}>
                    <div
                        className="mx-auto max-w-5xl divide-y divide-gray-200 rounded-lg bg-gray-200 shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">

                        <div>
                            {blok.items.map((action, actionIdx) => (
                                <React.Fragment key={action.title + actionIdx}>
                                    <div

                                        {...storyblokEditable(action)}
                                        onClick={() => setCurrentIndex(actionIdx)}
                                        id={blokId + 'revealHeading' + actionIdx}
                                        className={classNames(
                                            {
                                                'relative ring-1 cursor-pointer flex hover:ring-amber-300 hover:z-20 justify-space-between items-center gap-4 group p-4': true,
                                                'bg-white z-10 ring-amber-300': currentIndex === actionIdx,
                                                'bg-gray-50 ring-transparent': currentIndex !== actionIdx,
                                            },
                                            actionIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
                                            actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
                                            actionIdx === blok.items.length - 1 ? 'sm:rounded-bl-lg' : '',
                                            // actionIdx === blok.items.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '',
                                        )}
                                    >
                                        <div>
                                    <span
                                        className={classNames(
                                            action.background_color || 'bg-indigo-50', //action.iconBackground,
                                            action.color || 'text-primary',
                                            'rounded-lg inline-flex p-3 ring-4 ring-white'
                                        )}
                                    >
                                      <span
                                          dangerouslySetInnerHTML={{
                                              __html: (action.icon)
                                          }}
                                      />
                                    </span>
                                        </div>
                                        <div className="">
                                            <h3 className="text-base font-semibold leading-6 text-gray-900">

                                                {/* Extend touch target to entire panel */}
                                                <span className="absolute inset-0" aria-hidden="true"/>
                                                {action.title}

                                            </h3>
                                            <div className="mt-2 text-sm text-gray-500">
                                                <div className=""
                                                     dangerouslySetInnerHTML={{
                                                         __html: render(action.subtitle) as string
                                                     }}
                                                >
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            className="pointer-events-none ml-auto text-gray-300 group-hover:text-gray-400"
                                            aria-hidden="true"
                                        >
                                            <ArrowRightIcon className={'w-6 h-6'}/>
                                        </div>
                                    </div>
                                    {actionIdx === currentIndex &&
                                        <div className={'bg-white py-8 px-6 sm:rounded-lg sm:hidden'}>
                                            {nestedBlocks}
                                        </div>}
                                </React.Fragment>
                            ))}
                        </div>

                        <div className={'bg-white py-8 px-6 rounded-tr-lg rounded-br-lg hidden sm:block'}>
                            {nestedBlocks}
                        </div>


                    </div>
                </div>
            </div>
        </div>
    )
}
